import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from "@angular/router";

import { AuthenticationService } from "app/auth/service";

@Injectable({ providedIn: "root" })
export class NoAuthGuard implements CanActivate, CanActivateChild {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      (state.url.includes("authentication") ||
        state.url.includes("survey/login")) &&
      this._authenticationService.currentUserValue
    ) {
      this._router.navigate(["/"]);
    }

    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (
      state.url.includes("authentication") &&
      this._authenticationService.currentUserValue
    ) {
      this._router.navigate(["/"]);
    }

    return true;
  }
}
