<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo" *ngIf="coreConfig.app.appLogoImage">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>

    <!-- Menu Toggler -->
    <!-- <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <!-- MENU -->
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>

  <!-- PROJECTS AND EVENT GROUPS -->
  <ul class="onxite--project-barlist navigation navigation-main">
    <li class="onxite--header-title">
      <span>Recent Projects</span>
    </li>
    <li
      *ngFor="let project of $projects | async"
      class="nav-item has-sub ox--project-menu"
      id="project-menuitem-{{ project.id }}"
    >
      <a>
        <div (click)="displaySubmenu('project', project.id)">
          <span [data-feather]="'chevrons-up'"></span
          ><span>{{ project.name }}</span>
        </div>
        <ul
          class="menu-content onxite--project-content ox--group-menu"
          id="group-submenu-{{ project.id }}"
        >
          <ng-container
            *ngFor="let group of project.eventGroups; let x = index"
          >
            <li
              *ngIf="x < 3"
              class="nav-item has-sub ox--group-menuitem"
              id="group-menuitem-{{ group.id }}"
            >
              <a>
                <div (click)="displaySubmenu('group', project.id, group.id)">
                  <span [data-feather]="'circle'"></span
                  ><span>{{ group.name }}</span>
                </div>
                <ul
                  class="menu-content onxite--group-content ox--event-menu"
                  id="event-submenu-{{ group.id }}"
                >
                  <ng-container
                    *ngFor="let event of group.events; let y = index"
                  >
                    <li
                      *ngIf="y < 3"
                      class="nav-item ox--event-menuitem"
                      id="event-menuitem-{{ event.id }}"
                    >
                      <a>
                        <div
                          [routerLink]="['/event/dashboard', event.id]"
                          [routerLinkActive]="'selected'"
                          (click)="navigateTo('/event/dashboard', event.id)"
                        >
                          <span>{{ event.name }}</span>
                        </div>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </a>
            </li>
          </ng-container>
          <li>
            <button
              class="btn btn-icon btn-secondary onxite--btn-secondary"
              type="button"
              [routerLink]="['/event/groups', project.id]"
              rippleEffect
            >
              <span>All event groups</span>
            </button>
          </li>
        </ul>
      </a>
    </li>

    <li class="text-center" *ngIf="!coreConfig.isProjectsPage">
      <button
        class="btn btn-icon btn-secondary onxite--btn-secondary"
        type="button"
        [routerLink]="['/event/projects']"
        rippleEffect
      >
        <span>All projects</span>
      </button>
    </li>
  </ul>

  
</div>
<ul class="onxite--menubar-last">
  <li *ngIf="!coreConfig.isProjectsPage">
    <button
      class="btn btn-icon btn-primary"
      type="button"
      [routerLink]="['/event/projects']"
      [queryParams]="{ project: 'new' }"
      rippleEffect
    >
      <span>New project</span>
    </button>
  </li>
  <li class="ox--logout">
    <a type="button" class="nav-link nav-link-style btn" (click)="logout()">
      <span [data-feather]="'log-out'" class="ficon font-medium-5 feather"></span>
    </a>
  </li>
</ul>
<!--/ Main menu -->
