import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { ModalModule } from "app/layout/components/modal/modal.module";
import { PaginationModule } from "app/layout/components/pagination/pagination.module";
import { ProjectsComponent } from "./projects.component";
import { ProjectsService } from "./projects.service";

@NgModule({
  declarations: [ProjectsComponent],
  imports: [
    CoreCommonModule,
    NgbModule,
    ContentHeaderModule,
    ModalModule,
    PaginationModule,
    RouterModule,
  ],
  providers: [ProjectsService],
})
export class ProjectsModule {}
