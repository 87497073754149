import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Location } from "@angular/common";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "app/auth/service";

@Component({
  selector: "vertical-layout",
  templateUrl: "./vertical-layout.component.html",
  styleUrls: ["./vertical-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  coreConfig: any;
  public isLogin: boolean;
  public isSurvey: boolean;
  public isReport: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private location: Location,
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.location.onUrlChange((path) => {
      this.isLogin = path.includes("/authentication");
      this.isSurvey = path.includes("/survey/");
      this.isReport = path.includes("/report/");
      // top bar
      this.coreConfig.layout.navbar.hidden = false;
      this.coreConfig.isProjectsPage = path.includes("/event/projects");
      
      // lateral panel
      this.coreConfig.layout.menu.hidden = this.isReport || this.isSurvey || this.isLogin;
    });
  }

  logout() {
    this._authenticationService.logout();
    this._router.navigate(["/authentication/login"]);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
