<!-- Basic Modal -->
<ng-template #modalBasic let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modalBasic">{{ displayModal.title }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus [innerHTML]="displayModal.description"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cancel click')" rippleEffect>
      No, cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
      {{ displayModal.buttonText }}
    </button>
  </div>
</ng-template>
<!-- / Basic Modal -->

<!-- Delete Modal -->
<ng-template #modalDelete let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modalDelete">{{ displayModal.title }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    Are you absolutely sure?
    <span class="ox--delete-advice">
      This action cannot be undone. You will lose this item and all related components.
      
     <span>
      Please type the following to confirm: <code>{{ displayModal.itemName }}</code>

      <div class="form-group">
        <input type="text" [(ngModel)]="itemName" (ngModelChange)="onChange($event)" class="form-control" appNoPaste>
      </div>
     </span>
    </span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cancel click')" rippleEffect>
      No, cancel
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Accept click')" [disabled]="disableDeleteBtn ? true : null" rippleEffect>
      {{ displayModal.buttonText }}
    </button>
  </div>
</ng-template>
<!-- / Delete Modal -->

<!-- Form Modal -->
<ng-template #modalForm let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modalForm">{{ displayModal.title }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <ng-content></ng-content>
  </div>
  <div class="modal-footer" *ngIf="formStructType !== 'wizard'">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cancel click')" rippleEffect>
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" disabled="{{ displayModal.enableButton ? null : 'disabled' }}" rippleEffect>
      {{ displayModal.buttonText }}
    </button>
  </div>
</ng-template>
<!-- / Form Modal -->
