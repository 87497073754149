<ngb-pagination
  [collectionSize]="pagination.total"
  [(page)]="pageAdvancedEllipses"
  [maxSize]="3"
  [pageSize]="pagination.limit"
  [rotate]="true"
  (pageChange)="changePage($event)"
>
  <ng-template ngbPaginationFirst>First</ng-template>
  <ng-template ngbPaginationPrevious
    ><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span
    >&nbsp;
  </ng-template>
  <ng-template ngbPaginationNext
    ><span
      [data-feather]="'chevron-right'"
      [class]="'font-weight-bolder'"
    ></span>&nbsp;
  </ng-template>
  <ng-template ngbPaginationLast>Last</ng-template>
</ngb-pagination>