<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
  </div>

  <div class="ox--mobile-menu-logo">
    <span class="brand-logo">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
    </span>
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto d-sm-flex d-none">
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <ng-container *ngIf="currentUser">
          <div class="user-nav">
            <span class="user-name">
              {{ currentUser.user?.firstname }} {{ currentUser.user?.lastname }}
            </span>
          </div>
          <span class="avatar">
            <img
              class="round"
              [src]="currentUser.user?.avatar ? imageContainer + '/avatars/' + currentUser.user.avatar : 'assets/images/avatars/avatar.png'"
              alt="avatar"
              height="40"
              width="40" />
          </span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem (click)="modalOpen(currentUser.user.id)">
          <span [data-feather]="'user'" [class]="'mr-50'"></span> My Settings
        </a>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'">
          </span> Logout
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<app-modal (modalStatus)="changeModalStatus($event)">
  <ng-container [formGroup]="formUser">
    <div class="row ox--my-profile-form">
      <div class="col-xl-12 col-md-12 col-12 mb-1">
        <div class="form-group">
          <label for="user_avatar" class="ox--image-preview" [ngClass]="!userAvatar && !userAvatarLoaded ? 'no-image' : null">
            <ng-container *ngIf="formUser.get('avatar').value; else tempImage">
              <img [src]="userAvatar" alt="User image preview 1" (error)="userAvatar = userAvatarLoaded">
            </ng-container>
            <ng-template #tempImage>
              <img [src]="userAvatarLoaded" alt="User image preview 2" *ngIf="userAvatarLoaded">
            </ng-template>
          </label>

          <div class="progress-wrapper" *ngIf="isUploadingImage">
            <ngb-progressbar [value]="progressBarValue" [striped]="true" [animated]="true"></ngb-progressbar>
          </div>
          
          <input
            type="file"
            class="form-control"
            id="user_avatar"
            formControlName="imageRaw"
            autocomplete="off"
            accept="image/*"
            (change)="displayImagePreview($event)"
            readonly
          />
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 mb-1">
        <div class="form-group">
          <label for="user_firstname">Firstname</label>
          <input
            type="text"
            class="form-control"
            id="user_firstname"
            formControlName="firstname"
            placeholder="Enter firstname"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 mb-1">
        <div class="form-group">
          <label for="user_lastname">Lastname</label>
          <input
            type="text"
            class="form-control"
            id="user_lastname"
            formControlName="lastname"
            placeholder="Enter lastname"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="col-xl-12 col-md-12 col-12 mb-1">
        <div class="form-group">
          <label for="user_email">Email</label>
          <input
            type="text"
            class="form-control"
            id="user_email"
            formControlName="email"
            autocomplete="off"
            readonly
          />
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 mb-1">
        <div class="form-group">
          <label for="user_company">Company</label>
          <input
            type="text"
            class="form-control"
            id="user_company"
            formControlName="company"
            autocomplete="off"
            readonly
          />
        </div>
      </div>
      <!-- <div class="col-xl-6 col-md-6 col-12 mb-1">
        <div class="form-group">
          <label for="user_phone">Phone</label>
          <input
            inputmode="decimal"
            pattern="[0-9]*"
            type="text"
            class="form-control"
            id="user_phone"
            formControlName="phone"
            autocomplete="off"
            appOnlyNumbers
          />
        </div>
      </div> -->
    </div>
  </ng-container>
</app-modal>