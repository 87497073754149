import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "environments/environment";
import {
  Company,
  CreateCompanyDto,
  UpdateCompanyDto,
} from "app/common/interfaces/company.type";
import { PaginationOptions } from "app/common/interfaces/pagination.type";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.apiUrl}/companies`;
  }

  getAll(pagination?: PaginationOptions) {
    const url = pagination ? `${this.apiUrl}?limit=${pagination.limit}&offset=${pagination.offset}` : this.apiUrl;
    return this.http.get<Company[]>(url);
  }

  getAllForProjects() {
    const url = `${this.apiUrl}/projects`;
    return this.http.get<Company[]>(url);
  }

  getById(companyId: number) {
    const url = `${this.apiUrl}/${companyId}`;
    return this.http.get<Company>(url);
  }

  search(pagination: PaginationOptions, search: string) {
    const url = `${this.apiUrl}/search?limit=${pagination.limit}&offset=${pagination.offset}&search=${search}`;
    return this.http.get<Company[]>(url);
  }

  create(body: CreateCompanyDto) {
    const url = this.apiUrl;
    return this.http.post(url, body);
  }

  update(companyId: number, body: UpdateCompanyDto) {
    const url = `${this.apiUrl}/${companyId}`;
    return this.http.put(url, body);
  }

  delete(companyId: number) {
    const url = `${this.apiUrl}/${companyId}`;
    return this.http.put(url, { status: "DELETED" });
  }
}
