import { NgModule } from "@angular/core";
import { CoreCommonModule } from "@core/common.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from "./toast.service";
import { ToastComponent } from './toast.component';

@NgModule({
  declarations: [
    ToastComponent
  ],
  exports: [ToastComponent],
  imports: [CoreCommonModule, NgbModule],
  providers: [ToastService],
})
export class ToastModule {}
