import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { environment } from "environments/environment";
import { ProjectsService } from "./projects.service";
import { Project } from "app/common/interfaces/project.type";
import { CompaniesService } from "app/main/admin/companies/companies.service";
import { Company } from "app/common/interfaces/company.type";
import { ModalOptions, ModalClose } from "app/common/interfaces/modal.type";
import { File } from "app/common/interfaces/file.type";
import { PaginationOptions } from "app/common/interfaces/pagination.type";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "app/auth/service";
import { ToastService } from "app/layout/components/toast/toast.service";
import { ModalService } from "app/layout/components/modal/modal.service";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectsComponent implements OnInit, AfterViewInit {
  public contentHeader: object;
  public currentUser: any;
  public displayModal: ModalOptions;
  public formProject: FormGroup;
  public imageContainer: string;
  public pagination: PaginationOptions;
  public projects: Project[];
  public searchItem: string;
  public viewType: string;

  $companies: Observable<Company[]>;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private fb: FormBuilder,
    private _projectsService: ProjectsService,
    private _companiesService: CompaniesService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _toastService: ToastService,
    private _modalService: ModalService,
    private _router: Router,
  ) {
    this._unsubscribeAll = new Subject();
    this.imageContainer = environment.spaceUrl;

    this.formProject = this.fb.group({
      id: [],
      name: ["", Validators.required],
      company: ["", Validators.required],
      imageRaw: [],
      image: [],
      status: ["ACTIVE"],
    });
    this.projects = [];

    this.pagination = {
      limit: 8,
      offset: 0,
      total: 0,
    };
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: "My projects",
      actionButton: false,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };

    if (!localStorage.getItem("viewType")) {
      this.viewType = "grid";
      localStorage.setItem("viewType", this.viewType);
    } else {
      this.viewType = localStorage.getItem("viewType");
    }

    this.formProject.valueChanges.subscribe(() => {
      if (this.formProject.valid) {
        this._modalService.options({
          ...this.displayModal,
          enableButton: true,
        });
      } else {
        this._modalService.options({
          ...this.displayModal,
          enableButton: false,
        });
      }
    });

    this.loadProjects(this.pagination);
    this.currentUser = (
      this._authenticationService.currentUserValue as any
    ).user;
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.project === "new") {
        this.modalOpen("create", null);
      }
    });
  }

  loadProjects(pagination: PaginationOptions) {
    this._projectsService
      .getAllByCompany(pagination)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.projects = res.rows;

        this.pagination = {
          limit: pagination.limit,
          offset: pagination.offset,
          total: res.count,
        };
      });
  }

  toggleView(type: string) {
    if (type === "grid") {
      this.viewType = "grid";
      return localStorage.setItem("viewType", this.viewType);
    }

    this.viewType = "list";
    return localStorage.setItem("viewType", this.viewType);
  }

  modalOpen(type: string, projectId: number) {
    this._modalService.open(type);
    if (projectId) {
      if (type === "edit") {
        this._projectsService
          .getById(projectId)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((project) => {
            this.formProject.get("id").setValue(project.id);
            this.formProject.get("name").setValue(project.name);
            this.formProject
              .get("company")
              .setValue((project.company as any).id);
            this.formProject.get("image").setValue(project.image);
          });
        this._modalService.options(
          (this.displayModal = {
            title: "Edit project",
            buttonText: "Update",
            enableButton: false,
          })
        );
      } else if (type === "duplicate") {
        this.formProject.get("id").setValue(projectId);
        this._modalService.open('duplicate');
        this._modalService.options(
          (this.displayModal = {
            title: "Are you sure?",
            buttonText: "Duplicate",
            enableButton: false,
            description: "Duplicate <b>Project</b>",
          })
        );
      } else {
        this.formProject.get("id").setValue(projectId);
        this._modalService.options(
          (this.displayModal = {
            title: "Delete project",
            buttonText: "Yes, delete",
            enableButton: false,
            itemType: "project",
            itemName: this.projects.find((group) => {
              return group.id === projectId;
            }).name,
          })
        );
      }
    } else {
      this.formProject
        .get("company")
        .setValue(
          this.currentUser.company.type !== "ADMIN"
            ? this.currentUser.company.id
            : ""
        );
      this._modalService.options(
        (this.displayModal = {
          title: "Create project",
          buttonText: "Create",
          enableButton: false,
        })
      );
    }

    this.$companies = this._companiesService.getAllForProjects();
  }

  changeModalStatus(closeValues: ModalClose) {
    if (closeValues.reason === "accept") {
      if (closeValues.type === "create") {
        delete this.formProject.value.id;
        delete this.formProject.value.imageRaw;
        this.formProject.value.company = this.formProject.value.company || null;
        this._projectsService
          .create(this.formProject.value)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.loadProjects(this.pagination);
            const savedText = "Project was created successfully";
            this._toastService.show(savedText, {
              autohide: true,
              headerTitle: "Success",
              icon: "check",
              iconColorClass: "text-success",
            });

            this._router.navigate(["/event/groups", res.id]);
          });
      } else if (closeValues.type === "edit") {
        this._projectsService
          .update(
            this.formProject.get("id").value,
            this.getDirtyValues(this.formProject)
          )
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => {
            this.loadProjects(this.pagination);
            const savedText = "Project was updated successfully";
            this._toastService.show(savedText, {
              autohide: true,
              headerTitle: "Success",
              icon: "check",
              iconColorClass: "text-success",
            });
          });
      } else if (closeValues.type === "delete") {
        this._projectsService
          .delete(this.formProject.get("id").value)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => {
            this.loadProjects(this.pagination);
            const savedText = "Project was deleted successfully";
            this._toastService.show(savedText, {
              autohide: true,
              headerTitle: "Success",
              icon: "check",
              iconColorClass: "text-success",
            });
          });
      } else if (closeValues.type === "duplicate") {
        this.duplicate(this.formProject.get("id").value);
      }
    }

    this.formProject.reset();
    this.formProject.get("company").setValue("");
    this.formProject.get("status").setValue("ACTIVE");

    this._modalService.close();
    this._modalService.options({ ...this.displayModal, enableButton: false });
  }

  duplicate(projectId: number) {
    this._projectsService.duplicate(projectId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.loadProjects(this.pagination);
        this.formProject.get("id").reset();
        const savedText = "Project was duplicated successfully";
        this._toastService.show(savedText, {
          autohide: true,
          headerTitle: "Success",
          icon: "check",
          iconColorClass: "text-success",
        });
      });
  }

  async uploadFile(file: any) {
    const fileData = new FormData();
    const fileLoaded = file.target.files[0];

    fileData.append("image", fileLoaded, fileLoaded.name);

    if (fileLoaded.size > 200000) {
      this._toastService.show('The file uploaded exceed weight parameters', {
        autohide: true,
        headerTitle: "Error",
        icon: 'alert-circle',
        iconColorClass: "text-danger",
      });

      return;
    }

    if (fileLoaded) {
      await this._projectsService.upload(fileData).subscribe((f: File) => {
        this.formProject.get("image").setValue(f.filename);
      });
    }
  }

  search() {
    this._projectsService
      .search(this.pagination, this.searchItem)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.projects = res.rows;

        this.pagination = {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          total: res.count,
        };
      });
  }

  searchName(type: string, elem: any) {
    if (elem.target.value) {
      if (type === "group") {
        this._projectsService
          .searchByType("name", elem.target.value)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            if (res) {
              this.formProject.get("name").setErrors({ nameExist: true });
            } else {
              this.formProject.get("name").setErrors(null);
            }
          });
      } else {
        this._projectsService
          .searchByType("name", elem.target.value)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            if (res) {
              this.formProject.get("name").setErrors({ nameExist: true });
            } else {
              this.formProject.get("name").setErrors(null);
            }
          });
      }
    }
  }

  paginate(pagination: PaginationOptions) {
    this.loadProjects(pagination);
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          if (key === "imageRaw") {
            dirtyValues["image"] = form.controls["image"].value;
          } else {
            dirtyValues[key] = currentControl.value;
          }
        }
      }
    });

    return dirtyValues;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
