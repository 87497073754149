<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="row">
      <div class="col-12">
        <div class="card onxite--title-navbar">
          <div class="card-body">
            <form action="#" class="invoice-repeater">
              <div>
                <div class="row d-flex justify-content-between">
                  <div class="col-md-5 col-12">
                    <h2>{{ contentHeader["headerTitle"] }}</h2>
                  </div>

                  <div class="col-md-7 col-12 onxite--common-fields">
                    <div class="row text-right">
                      <div class="onxite--col col-md-2 col-12">
                        <span
                          class="onxite--list-type"
                          [ngClass]="
                            viewType === 'list' ? 'onxite--active' : null
                          "
                          (click)="toggleView('list')"
                        >
                          <span [data-feather]="'menu'"></span>
                        </span>
                        <span
                          class="onxite--list-type"
                          [ngClass]="
                            viewType === 'grid' ? 'onxite--active' : null
                          "
                          (click)="toggleView('grid')"
                        >
                          <span [data-feather]="'square'"></span>
                        </span>
                      </div>
                      <div class="onxite--col col-md-7 col-12">
                        <!-- Search bar -->
                        <div class="onxite--field-search">
                          <div class="input-group input-group-merge">
                            <input
                              type="text"
                              class="form-control"
                              name="search"
                              [(ngModel)]="searchItem"
                              (ngModelChange)="search()"
                              placeholder="Search projects"
                              autocomplete="off"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer">
                                <i data-feather="search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!--/ Search bar -->
                      </div>
                      <div class="onxite--col col-md-3 col-12 ox--btn-new-project-wrapper">
                        <button
                          class="btn btn-icon btn-primary"
                          type="button"
                          (click)="modalOpen('create', null)"
                          rippleEffect
                        >
                          <span>Create new project</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="viewType === 'grid'; else listView">
      <section>
        <div class="row match-height">
          <ng-container *ngIf="projects.length > 0; else noRegisters">
            <div class="col-md-3 col-lg-3" *ngFor="let project of projects">
              <div
                class="card onxite--project"
                [ngClass]="project.image ? null : 'ox--default-image'"
              >
                <img
                  class="card-img-top"
                  [src]="
                    project.image
                      ? imageContainer + '/projects/' + project.image
                      : '/assets/images/icons/default-image.svg'
                  "
                  alt="{{ project.name }} image"
                  [routerLink]="['/event/groups', project.id]"
                />
                <span class="onxite--tile-options">
                  <a
                    href="javascript:void(0);"
                    (click)="modalOpen('edit', project.id)"
                    ><i data-feather="edit-2"></i></a
                  ><a
                    href="javascript:void(0);"
                    (click)="modalOpen('duplicate', project.id)"
                    ><i data-feather="copy" class="mr-50"></i></a
                  ><a
                    href="javascript:void(0);"
                    (click)="modalOpen('delete', project.id)"
                    ><i data-feather="trash"></i
                  ></a>
                </span>
                <div
                  class="card-body"
                  [routerLink]="['/event/groups', project.id]"
                >
                  <h4 class="card-title">{{ project.name }}</h4>
                  <p class="card-text">
                    {{ project.company.name }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #noRegisters>
            <div class="no-registers text-center">There are no registers</div>
          </ng-template>
        </div>
      </section>
    </ng-container>

    <ng-template #listView>
      <div class="table-responsive">
        <table class="table table-hover-animation ox--projects">
          <thead>
            <tr>
              <th></th>
              <th>Project</th>
              <th>Brand</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="projects.length > 0; else noRegisters">
              <tr *ngFor="let project of projects">
                <td>
                  <div
                    [ngClass]="project.image ? null : 'ox--row-default-image'"
                  >
                    <img
                      [src]="
                        project.image
                          ? imageContainer + '/projects/' + project.image
                          : '/assets/images/icons/default-image.svg'
                      "
                      alt="Avatar"
                      height="50"
                      width="50"
                    />
                  </div>
                </td>
                <td [routerLink]="['/event/groups', project.id]" class="ox--cursor-pointer">{{ project.name }}</td>
                <td>{{ project.company.name | titlecase }}</td>
                <td class="overflow-hidden">
                  <div ngbDropdown container="body">
                    <button
                      type="button"
                      class="btn btn-sm hide-arrow"
                      ngbDropdownToggle
                      data-toggle="dropdown"
                    >
                      <i data-feather="more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a
                        ngbDropdownItem
                        href="javascript:void(0);"
                        [routerLink]="['/event/groups', project.id]"
                        ><i data-feather="eye" class="mr-50"></i
                        ><span>View</span></a
                      >
                      <a
                        ngbDropdownItem
                        href="javascript:void(0);"
                        (click)="modalOpen('edit', project.id)"
                        ><i data-feather="edit-2" class="mr-50"></i
                        ><span>Edit</span></a
                      ><a
                        ngbDropdownItem
                        href="javascript:void(0);"
                        (click)="modalOpen('duplicate', project.id)"
                        ><i data-feather="copy" class="mr-50"></i
                        ><span>Duplicate</span></a
                      ><a
                        ngbDropdownItem
                        href="javascript:void(0);"
                        (click)="modalOpen('delete', project.id)"
                        ><i data-feather="trash" class="mr-50"></i
                        ><span>Delete</span></a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #noRegisters>
              <tr>
                <td colspan="4" class="text-center">There are no registers</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </ng-template>

    <app-pagination
      [pagination]="pagination"
      (paginate)="paginate($event)"
      *ngIf="projects.length > 0"
    ></app-pagination>
  </div>
</div>

<app-modal (modalStatus)="changeModalStatus($event)">
  <ng-container [formGroup]="formProject">
    <div class="row">
      <div class="col-xl-12 col-md-12 col-12 mb-1">
        <div class="form-group">
          <label for="project_name">Name</label>
          <input
            type="text"
            class="form-control"
            id="project_name"
            formControlName="name"
            placeholder="Name (name of event, property or sponsored event)"
            autocomplete="off"
            [class.error]="
              formProject.get('name').hasError('nameExist') &&
              formProject.get('name').touched
            "
            (blur)="searchName('group', $event)"
          />
          <span
            *ngIf="
              formProject.get('name').hasError('nameExist') &&
              formProject.get('name').touched
            "
            class="invalid-form"
          >
            <small class="form-text text-danger"
              >This name already exists!</small
            >
          </span>
        </div>
      </div>

      <ng-container *ngIf="currentUser.company.type === 'ADMIN'">
        <div class="col-xl-12 col-md-12 col-12 mb-1">
          <div class="form-group">
            <label for="project_brand">Brand/Company/Property</label>
            <select
              class="form-control"
              id="project_brand"
              formControlName="company"
            >
              <option value="">Select</option>
              <option
                *ngFor="let company of $companies | async"
                value="{{ company.id }}"
                [selected]="formProject.get('company').value === company.id"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
      </ng-container>

      <div class="col-xl-6 col-md-6 col-12 mb-1">
        <fieldset class="form-group">
          <label for="project_imageRaw">Image:</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              type="file"
              formControlName="imageRaw"
              accept="image/*"
              id="project_imageRaw"
              (change)="uploadFile($event)"
            />
            <label class="custom-file-label">
              {{
                formProject.get("image").value ? "File uploaded" : "Choose file"
              }}
            </label>
            <small
              ><i
                >Recomended resolution 325x240 px, max 200 KB, supported types:
                jpg, jpeg, gif, png</i
              ></small
            >
          </div>
        </fieldset>
      </div>
      <div class="col-xl-6 col-md-6 col-12 mb-1">
        <div
          class="onxite--image-uploaded"
          [ngClass]="
            formProject.get('image').value ? null : 'ox--default-image-edit'
          "
        >
          <img
            [src]="
              formProject.get('image').value
                ? imageContainer + '/projects/' + formProject.get('image').value
                : '/assets/images/icons/default-image.svg'
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </ng-container>
</app-modal>
