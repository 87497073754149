import { NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CoreCommonModule } from "@core/common.module";
import { PaginationComponent } from './pagination.component';



@NgModule({
  declarations: [
    PaginationComponent
  ],
  exports: [PaginationComponent],
  imports: [
    CoreCommonModule,
    NgbModule
  ]
})
export class PaginationModule { }
