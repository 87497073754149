import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInput(e: KeyboardEvent) {
    const formControl = e.target as any;
    const end = formControl.selectionEnd;
    const ASCIICode = formControl.value.substr(-1).charCodeAt(0);

    if (ASCIICode < 48 || ASCIICode > 57) {
      formControl.value = formControl.value.replace(/[^0-9]+/g, '');
    }

    if (formControl.value[end - 1] === '0') {
      formControl.value = formControl.value.replace(/^00/g, '0');
    }

    // No spaces at begin
    if (formControl.value.length === 1 && ASCIICode === 32) {
      formControl.value = formControl.value.replace(/[\s]+/g, '');
    }

    // Don't allow double space in a field
    if (ASCIICode === 32 && (formControl.value[end - 1] === ' ' || formControl.value[end] === ' ')) {
      formControl.value = formControl.value.replace(/\s\s+/g, ' ');
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}
