<!-- ngb-toast component -->
<ngb-toast
  *ngFor="let toast of _toastService.toasts"
  [class]="toast.classname"
  [autohide]="toast.autohide"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
>
  <!-- toast header -->
  <ng-template ngbToastHeader>
    <span [ngClass]="toast.iconColorClass">
      <i [data-feather]="toast.icon"></i>
    </span>
    <strong class="mr-auto toast-title">{{ toast.headerTitle }}</strong>
  </ng-template>
  <!--/ toast header -->

  <!-- toast body -->
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  <!--/ toast body -->
</ngb-toast>
<!--/ ngb-toast component -->