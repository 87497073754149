import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import {
  CreateUserDto,
  UpdateUserDto,
  User,
} from "app/common/interfaces/user.type";
import { PaginationOptions } from "app/common/interfaces/pagination.type";
import { environment } from "environments/environment";
import { ToastService } from "app/layout/components/toast/toast.service";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  apiUrl: string;

  constructor(private http: HttpClient, private _toastService: ToastService) {
    this.apiUrl = `${environment.apiUrl}/users`;
  }

  getAll(pagination: PaginationOptions) {
    const url = `${this.apiUrl}?limit=${pagination.limit}&offset=${pagination.offset}`;
    return this.http.get<User[]>(url);
  }

  getById(userId: string) {
    const url = `${this.apiUrl}/${userId}`;
    return this.http.get<User>(url);
  }

  search(pagination: PaginationOptions, search: string) {
    const url = `${this.apiUrl}/search?limit=${pagination.limit}&offset=${pagination.offset}&search=${search}`;
    return this.http.get<User[]>(url);
  }

  findByActivationHash(hash: string) {
    const url = `${this.apiUrl}/activation-hash`;
    const body = { activationHash: hash };
    return this.http.post(url, body);
  }

  create(body: CreateUserDto) {
    const url = this.apiUrl;
    return this.http.post(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  update(userId: string, body: UpdateUserDto) {
    const url = `${this.apiUrl}/${userId}`;
    return this.http.put(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  delete(userId: string) {
    const url = `${this.apiUrl}/${userId}`;
    return this.http.put(url, { status: "DELETED" }).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  forgotPassword(email: string) {
    const url = `${this.apiUrl}/change-password`;
    
    return this.http.put(url, email).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  resetPassword(data: any) {
    const url = `${this.apiUrl}/reset-password`;
    
    return this.http.post(url, data).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  changePassword(data: any) {
    const url = `${this.apiUrl}/change-password`;
    
    return this.http.post(url, data).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  upload(file: any) {
    const url = `${this.apiUrl}/avatar`;
    return this.http.post(url, file).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }
}
