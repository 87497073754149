import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { AuthUtils } from "../helpers/auth.utils";
import { ToastService } from "app/layout/components/toast/toast.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public enableSavingTemporary: boolean;
  public reportUrl: string;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _toastService: ToastService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.enableSavingTemporary = environment.enableLocalStorage;
    if (
      !this.reportUrl &&
      (this.currentUserValue as any) &&
      (this.currentUserValue as any).reporter
    ) {
      this.reportUrl = (
        this.currentUserValue as any
      ).reporter.event.reportUrl;
    }
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          this._toastService.show(error.error.message, {
            autohide: false,
            headerTitle: "Error",
            icon: "alert-circle",
            iconColorClass: "text-danger",
          });

          return throwError(error);
        })
      );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  reporterLogin(email: string, password: string, event: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/reporter/login`, {
        email,
        password,
        event,
      })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.reportUrl = user.reporter.event.reportUrl;

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          this._toastService.show(error.error.message, {
            autohide: false,
            headerTitle: "Error",
            icon: "alert-circle",
            iconColorClass: "text-danger",
          });

          return throwError(error);
        })
      );
  }

  /**
   * User logout
   *
   */
  logout(type?: string) {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    if (this.enableSavingTemporary) {
      localStorage.clear();
    }

    // notify
    this.currentUserSubject.next(null);

    if (type === "reporter") {
      this._router.navigate(["/survey/login", this.reportUrl]);
    } else {
      this._router.navigate(["/authentication/login"]);
    }
  }

  /**
   * Check the authentication status
   */
  check(currentUrl): Observable<boolean> {
    // Check the access token availability
    if (!this.currentUserValue?.access_token) {
      this.logout();
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.currentUserValue.access_token)) {
      this.logout();
      return of(false);
    }

    if (this.reportUrl) {
      this._router.navigate(["/survey/event", this.reportUrl]);
    } else {
      if (
        currentUrl.includes("survey/event") ||
        currentUrl.includes("survey/login")
      ) {
        this._router.navigate(["/"]);
        return of(false);
      }
    }

    // Check if is allowed for enter to a menu option
    if (currentUrl.includes("admin")) {
      const menus = (this.currentUserValue as any)?.menus;
      const menuAllowed = menus.children.find((m) => {
        return `/${m.url}` === currentUrl;
      });

      if (!!menuAllowed === false) {
        this._router.navigate(["/"]);
      }

      return of(!!menuAllowed);
    }

    // If the access token exists and it didn't expire, sign in using it
    return of(true);
  }

  /**
   * Check the authentication status
   */
  checkForReporter(currentUrl): Observable<boolean> {
    // Check the access token availability
    if (!this.currentUserValue?.access_token) {
      this.logout("reporter");
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.currentUserValue.access_token)) {
      this.logout("reporter");
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return of(true);
  }
}
