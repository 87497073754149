import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { OnlyNumbersDirective } from "./restricctions/only-numbers.directive";
import { OnlyNumbersDecimalsDirective } from "./restricctions/only-numbers-decimals.directive";
import { NoPasteDirective } from "./restricctions/no-paste.directive";
import { Password } from "./functionalities/password";

@NgModule({
  declarations: [
    OnlyNumbersDirective,
    OnlyNumbersDecimalsDirective,
    NoPasteDirective,
  ],
  exports: [
    OnlyNumbersDirective,
    OnlyNumbersDecimalsDirective,
    NoPasteDirective,
  ],
  providers: [Password],
  imports: [CommonModule],
})
export class UtilsModule {}
