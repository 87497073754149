import { Injectable } from "@angular/core";
import { ModalOpen, ModalOptions } from "app/common/interfaces/modal.type";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private display = new BehaviorSubject<ModalOpen>({ display: false });
  private contentOptions = new BehaviorSubject<ModalOptions>({});
  public modalStatus = this.display.asObservable();
  public modalContentOptions = this.contentOptions.asObservable();

  open(type: string) {
    this.display.next({ display: true, type });
  }

  close() {
    this.display.next({ display: false });
  }

  options(options: ModalOptions) {
    this.contentOptions.next({ ...options });
  }  
}
