import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

import { PaginationOptions } from "app/common/interfaces/pagination.type";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent implements OnInit {
  @HostBinding("class.onxite--pagination")
  @Output()
  paginate = new EventEmitter<PaginationOptions>();
  @Input() pagination: PaginationOptions;

  public pageAdvancedEllipses: number;

  constructor() {}

  ngOnInit(): void {
    this.pageAdvancedEllipses = 1;
  }

  changePage(numberPage: number) {
    this.paginate.emit({
      limit: this.pagination.limit,
      offset: this.pagination.limit * (numberPage - 1),
      total: this.pagination.total,
    });
  }
}
