import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { ModalComponent } from "./modal.component";
import { UtilsModule } from "app/utils/utils.module";
import { ModalService } from "./modal.service";

@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
  imports: [CommonModule, FormsModule, UtilsModule],
  providers: [ModalService],
})
export class ModalModule {}
