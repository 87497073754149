import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { AuthGuard, JwtInterceptor } from "./auth/helpers";
import { NoAuthGuard } from "./auth/helpers/no-auth.guards";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "event",
    pathMatch: "full",
    canActivateChild: [AuthGuard],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./main/admin/admin.module").then((m) => m.AdminModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "survey",
    loadChildren: () =>
      import("./main/survey/survey.module").then((m) => m.SurveyModule),
  },
  {
    path: "event",
    loadChildren: () =>
      import("./main/event/event.module").then((m) => m.EventModule),
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
    canActivateChild: [NoAuthGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
    canActivateChild: [NoAuthGuard],
  },
  // {
  //   path: "**",
  //   redirectTo: "pages/error",
  // },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
