import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "environments/environment";
import {
  Project,
  CreateProjectDto,
  UpdateProjectDto,
} from "app/common/interfaces/project.type";
import { PaginationOptions } from "app/common/interfaces/pagination.type";
import { ToastService } from "app/layout/components/toast/toast.service";

@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  apiUrl: string;

  constructor(private http: HttpClient, private _toastService: ToastService) {
    this.apiUrl = `${environment.apiUrl}/projects`;
  }

  getAll(pagination: PaginationOptions) {
    const url = `${this.apiUrl}?limit=${pagination.limit}&offset=${pagination.offset}`;
    return this.http.get<Project[]>(url);
  }

  getRecents(pagination: PaginationOptions) {
    const url = `${this.apiUrl}/recents?limit=${pagination.limit}&offset=${pagination.offset}`;
    return this.http.get<Project[]>(url);
  }

  getAllByCompany(pagination: PaginationOptions) {
    const url = `${this.apiUrl}/company?limit=${pagination.limit}&offset=${pagination.offset}`;
    return this.http.get<Project[]>(url);
  }

  search(pagination: PaginationOptions, search: string) {
    const url = `${this.apiUrl}/search?limit=${pagination.limit}&offset=${pagination.offset}&search=${search}`;
    return this.http.get<Project[]>(url);
  }

  searchByType(type: string, search: string) {
    const url = `${this.apiUrl}/search/${type}/${search}`;
    return this.http.get<Project>(url);
  }

  duplicate(projectId: number) {
    const url = `${this.apiUrl}/copy/${projectId}`;
    return this.http.get(url);
  }

  getById(projectId: number) {
    const url = `${this.apiUrl}/${projectId}`;
    return this.http.get<Project>(url);
  }

  create(body: CreateProjectDto) {
    const url = this.apiUrl;
    return this.http.post(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  upload(file: any) {
    const url = `${this.apiUrl}/images`;
    return this.http.post(url, file).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  update(projectId: number, body: UpdateProjectDto) {
    const url = `${this.apiUrl}/${projectId}`;
    return this.http.put(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }

  delete(projectId: number) {
    const url = `${this.apiUrl}/${projectId}`;
    return this.http.put(url, { status: "DELETED" }).pipe(
      catchError((error: HttpErrorResponse) => {
        this._toastService.show(error.error.message, {
          autohide: false,
          headerTitle: 'Error',
          icon: 'alert-circle',
          iconColorClass: 'text-danger'
        });

        return throwError(error);
      })
    );
  }
}
