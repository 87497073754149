import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { User } from 'app/auth/models';
import { UsersService } from "../../../main/admin/users/users.service";
import { environment } from 'environments/environment';
import { ModalClose, ModalOptions } from 'app/common/interfaces/modal.type';
import { ModalService } from '../modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  public imageContainer: string;
  public displayModal: ModalOptions;
  public formUser: FormGroup;
  public userAvatar: any;
  public userAvatarLoaded: any
  public isUploadingImage: boolean;
  public progressBarValue: number;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _modalService: ModalService,
    private fb: FormBuilder,
    private _toastService: ToastService,
    private _usersService: UsersService,
  ) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.imageContainer = environment.spaceUrl;

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.isUploadingImage = false;
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
  }

  modalOpen(userId: string) {
    this._modalService.open('edit');

    if (userId) {
      this._modalService.options(
        (this.displayModal = {
          title: "My Settings",
          buttonText: "Update",
          enableButton: false,
          itemType: "user",
          itemName: '',
        })
      );
    }
  }

  changeModalStatus(closeValues: ModalClose) {
    if (closeValues.reason === "accept") {
      
      if (Object.keys(this.getDirtyValues(this.formUser)).includes('email') || Object.keys(this.getDirtyValues(this.formUser)).includes('company')) {
        return;
      }

      const currentUserUpdated = {
        ...this.currentUser,
        user: {
          ...this.currentUser.user,
          ...this.getDirtyValues(this.formUser)
        }
      }; 
      
      this._usersService
        .update(
          this.formUser.get("id").value,
          this.getDirtyValues(this.formUser)
        )
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          localStorage.setItem("currentUser", JSON.stringify(currentUserUpdated));
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        });
    } else {
      if (!this.currentUser.user.avatar) {
        this.userAvatar = '';
        this.formUser.get("avatar").setValue('');
      } else {
        this.userAvatar = `${this.imageContainer}/avatars/${this.currentUser.user.avatar}`;
        this.formUser.get("avatar").setValue(this.currentUser.user.avatar);
      }
    }

    this.userAvatarLoaded = '';

    this._modalService.close();
    this._modalService.options({ ...this.displayModal, enableButton: false });
  }

  displayImagePreview(event: any) {
    const files = event.target.files;
    const reader = new FileReader();
    const mimeType = files[0]?.type;

    if (files.length === 0) {
      return;
    }

    if (files[0]?.size > 10000000) {
      this._toastService.show("The file uploaded exceed weight parameters", {
        autohide: true,
        headerTitle: "Error",
        icon: "alert-circle",
        iconColorClass: "text-danger",
      });

      this.userAvatarLoaded = '';
      return;
    }

    if (mimeType.match(/image\/*/) === null) {
      return;
    }

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.userAvatarLoaded = reader.result;
      this.uploadImage(files[0]);
    };
  }

  uploadImage(imageFile: any) {
    this.progressBarValue = 0;
    this.isUploadingImage = true;
    this.formUser.get("avatar").updateValueAndValidity();

    
    const setTimerBar = setInterval(() => {
      this.progressBarValue++;
    }, 900);

    const fileData = new FormData();
    const fileLoaded: any = imageFile;

    fileData.append("image", fileLoaded, fileLoaded.name);

    if (fileLoaded) {
      this._usersService
        .upload(fileData)
        .pipe(
          catchError((err) => {
            return of(err);
          })
        )
        .subscribe(async (f: File | any) => {
          if (f.error) {
            this._toastService.show(
              "There was an error, please try again later",
              {
                autohide: true,
                headerTitle: "Error",
                icon: "alert-circle",
                iconColorClass: "text-danger",
              }
            );
            this.isUploadingImage = false;
            const progressBar = document.getElementById('ox--progress-bar');
            progressBar.classList.add('error-loading')
          } else {
            this.progressBarValue = 100;
            await this.timeout(500);
            this.formUser.get("avatar").setValue(f.filename);
            this.userAvatar = `${this.imageContainer}/avatars/${f.filename}`;
            this.isUploadingImage = false;
            this.formUser.get("avatar").updateValueAndValidity();
          }
          clearInterval(setTimerBar);
        });
    }
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          if (key === "imageRaw") {
            dirtyValues["avatar"] = form.controls["avatar"].value;
          } else {
            dirtyValues[key] = currentControl.value;
          }
        }
      }
    });

    return dirtyValues;
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get the currentUser details from localStorage
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));    

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

    this.formUser = this.fb.group({
      id: [this.currentUser?.user?.id],
      firstname: [this.currentUser?.user?.firstname, Validators.required],
      lastname: [this.currentUser?.user?.lastname, Validators.required],
      email: [this.currentUser?.user?.email],
      profile: [this.currentUser?.user?.profile.name],
      company: [this.currentUser?.user?.company.name],
      phone: [this.currentUser?.user?.phone],
      avatar: [this.currentUser?.user?.avatar],
      imageRaw: [],
      status: [this.currentUser?.user?.status],
    });

    if (this.currentUser?.user?.avatar) {
      this.userAvatar = `${this.imageContainer}/avatars/${this.currentUser.user.avatar}`;
    }

    this.formUser.valueChanges.subscribe(() => {
      if (this.isUploadingImage) {
        this._modalService.options({
          ...this.displayModal,
          enableButton: false,
        });
      } else {
        if (this.formUser.valid) {
          this._modalService.options({
            ...this.displayModal,
            enableButton: true,
          });
        } else {
          this._modalService.options({
            ...this.displayModal,
            enableButton: false,
          });
        }
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
