import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import {
  ModalClose,
  ModalOptions,
} from "app/common/interfaces/modal.type";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalService } from "./modal.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  @ViewChild("modalBasic") modalBasic: ElementRef;
  @ViewChild("modalDelete") modalDelete: ElementRef;
  @ViewChild("modalForm") modalForm: ElementRef;
  @Output() modalStatus = new EventEmitter<ModalClose>();
  @Output() isModalOpen = new EventEmitter<boolean>();

  public disableDeleteBtn: boolean;
  public displayModal: ModalOptions;
  public itemName: string;
  public formStructType: string;
  public modalType: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private modal: NgbModal, public _modalService: ModalService) {
    this.disableDeleteBtn = true;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._modalService.modalStatus
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((open) => {
        if (open.display) {
          this.formStructType = open.type;

          switch (this.formStructType) {
            case "basic":
            case "duplicate":
              this.modalType = this.modalBasic;
              break;

            case "create":
            case "edit":
            case "wizard":
              this.modalType = this.modalForm;
              break;

            case "delete":
              this.modalType = this.modalDelete;
              break;

            default:
              this.modalType = this.modalBasic;
              break;
          }

          this.modal
            .open(this.modalType, {
              centered: true,
              windowClass: "modal ox--modal-component",
              size: this.formStructType === "wizard" ? "lg" : "sm",
              backdrop: "static",
            })
            .result.then(
              () => {
                this.modalStatus.emit({
                  close: true,
                  reason: "accept",
                  type: this.formStructType,
                  form: this.displayModal.itemType,
                });
                this.itemName = "";
              },
              (reason) => {
                this.modalStatus.emit({
                  close: true,
                  reason,
                  type: this.formStructType,
                  form: this.displayModal.itemType,
                });
                this.itemName = "";
              }
            );
        } else {
          this.modal.dismissAll();
        }

        if (open.type === 'wizard') {
          this.isModalOpen.emit(this.modal.hasOpenModals());
        }
      });

    this._modalService.modalContentOptions
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((options) => {
        this.displayModal = options;
      });
  }

  onChange(value: string) {
    if (value === this.displayModal.itemName) {
      this.disableDeleteBtn = false;
    } else {
      this.disableDeleteBtn = true;
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
