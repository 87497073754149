import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { Observable, Subject } from "rxjs";
import { take, takeUntil, filter, map } from "rxjs/operators";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";

import { CoreConfigService } from "@core/services/config.service";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ProjectsService } from "app/main/event/projects/projects.service";
import { Project } from "app/common/interfaces/project.type";
import { PaginationOptions } from "app/common/interfaces/pagination.type";
import { AuthenticationService } from "app/auth/service";

@Component({
  selector: "vertical-menu",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  isCollapsed: boolean;
  isScrolled: boolean = false;

  public $projects: Observable<Project[]>;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {Router} _router
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _coreMenuService: CoreMenuService,
    private _coreSidebarService: CoreSidebarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _projectsService: ProjectsService,
    private _authenticationService: AuthenticationService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    this.isCollapsed =
      this._coreSidebarService.getSidebarRegistry("menu").collapsed;

    // Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._coreSidebarService.getSidebarRegistry("menu")) {
          this._coreSidebarService.getSidebarRegistry("menu").close();
        }
      });

    // scroll to active on navigation end
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.directiveRef.scrollToElement(".navigation .active", -180, 500);
        });
      });

    // Get current menu
    this._coreMenuService.onMenuChanged
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.menu = this._coreMenuService.getCurrentMenu();
      });

    this.loadProjects({
      limit: 5,
      offset: 0,
      total: 0,
    });
  }

  loadProjects(pagination: PaginationOptions) {
    this.$projects = this._projectsService
      .getRecents(pagination)
      .pipe(map((res: any) => res.rows));
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  logout() {
    this._authenticationService.logout();
    this._router.navigate(["/authentication/login"]);
  }

  displaySubmenu(type: string, projectId: number, groupId?: number) {
    let menu;
    let submenu;
    let item;

    const projectMenu: any = document.getElementsByClassName(
      "ox--project-menu open"
    );

    for (const elem of projectMenu) {
      if (elem.getAttribute("id") !== `project-menuitem-${projectId}`) {
        elem.classList.remove("open");

        for (const subElem of elem.children[0].children) {
          subElem.classList.remove("onxite--submenu-open");

          for (const subElem2 of subElem.children) {
            subElem2.classList.remove("selected");
            subElem2.classList.remove("open");

            for (const subElem3 of subElem2.children) {
              for (const subElem4 of subElem3.children) {
                if (subElem4.tagName === "UL") {
                  subElem4.classList.remove("onxite--submenu-open");
                }
              }
            }
          }
        }
      } else {
        for (const subElem of elem.children[0].children) {
          for (const subElem2 of subElem.children) {
            subElem2.classList.remove("selected");
            subElem2.classList.remove("open");

            for (const subElem3 of subElem2.children) {
              for (const subElem4 of subElem3.children) {
                if (subElem4.tagName === "UL") {
                  subElem4.classList.remove("onxite--submenu-open");
                }
              }
            }
          }
        }
      }
    }

    if (type === "project") {
      menu = document.getElementById(`project-menuitem-${projectId}`);
      submenu = document.getElementById(`group-submenu-${projectId}`);
    } else {
      menu = document.getElementById(`group-submenu-${projectId}`);
      submenu = document.getElementById(`event-submenu-${groupId}`);
      item = document.getElementById(`group-menuitem-${groupId}`);

      item.classList.toggle("selected");
      item.classList.toggle("open");
    }

    menu.classList.toggle("open");
    submenu.classList.toggle("onxite--submenu-open");
  }

  async navigateTo(url: string, parameter: string) {
    return this._router.navigateByUrl('/', { skipLocationChange: true })
      .then(()=>this._router.navigate([url, parameter]));
  }

  /**
   * On Sidebar scroll set isScrolled as true
   */
  onSidebarScroll(): void {
    if (this.directiveRef.position(true).y > '3') {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  /**
   * Toggle sidebar expanded status
   */
  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry("menu").toggleOpen();
  }

  /**
   * Toggle sidebar collapsed status
   */
  toggleSidebarCollapsible(): void {
    // Get the current menu state
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.isCollapsed = config.layout.menu.collapsed;
      });

    if (this.isCollapsed) {
      this._coreConfigService.setConfig(
        { layout: { menu: { collapsed: false } } },
        { emitEvent: true }
      );
    } else {
      this._coreConfigService.setConfig(
        { layout: { menu: { collapsed: true } } },
        { emitEvent: true }
      );
    }
  }
}
